import recallServices from '../../../../../services/provider/recall'
import ActionType from '../../../../../components/part/provider/ActionType'
import { COMMUNICATION_TYPE } from '../../../../../misc/commons'

export default {
    props: {
        communicationId: { type: String, default: null },
        providerCommunicationId: {type: Number, default: 0},
        communicationType: {type: String, default: ''},
        status: {type: String, default: ''},
    },
    components: {
        ActionType,
    },
    data() {
        return {
            actions: null,
            isExpanded: false,
            disabledBtn: false,
            cnt: 1,
            recall: COMMUNICATION_TYPE.RECALL,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
        }
    },
    created() {
        this.$emit('onReLoadCommunicationData')
        this.$emit('onReLoadHistoryData')
        this.getRecallActions()
    },
    methods: {
        getRecallActions() {
            recallServices.getRecallActions(this.communicationId, this.providerCommunicationId).then((resp) => {
                if (!resp.error) {
                    var actions=[]
                    resp.data.d.forEach((action) => {
                        if(action.isCustomActionType==false)
                        {
                            actions.push(action)
                        }
                        if(this.status == 'DRAFT')
                        {
                            this.disabledBtn = true
                        }
                        else{
                            let clidlActions = resp.data.d.filter(x => x.baseActionId == action.id)
                            if(clidlActions.length>0)
                            {
                                actions.push(action)
                            }
                        }

                    })
                    this.actions = actions
                    let baseactions = this.actions.filter(x => x.baseActionId == 0)
                    baseactions.forEach((action) => {
                        let clidlActions = this.actions.filter(x => x.baseActionId == action.id)
                        clidlActions.forEach((child) => {
                            child.description = child.description.replace(action.description + ' - ', '')
                        })

                    })
                    for (let i = 0; i < this.actions.length; i++) {
                        let List = []
                        let len = this.actions[i].actionValues.length
                        let act = this.actions[i]
                        this.actions[i].childactioncount=this.actions.filter(s=>s.baseActionId != 0 && s.baseActionId==this.actions[i].id).length
                        if (this.actions[i].action_type == 'MultipleChoice') {
                            for (let j = 0; j < len; j++) {
                                List.push({
                                    value: this.cnt,
                                    text: act.actionValues[j].value,
                                })
                                if (!act.isAllowOnlyOne) {
                                    act.response_data.valueMultiChoice.forEach((element) => {
                                        if (element.valueMultiChoice == act.actionValues[j].value) {
                                            act.multiSelectedValues.push(this.cnt)
                                        }
                                    })
                                } else {
                                    if(act.response_data.valueMultiChoice == undefined){
                                        if(act.response_data.valueSingleChoice != undefined){
                                            if (act.response_data.valueSingleChoice == act.actionValues[j].value ) {
                                                act.singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    else
                                    {
                                        if(act.response_data.valueMultiChoice.length > 0){
                                            if (act.response_data.valueMultiChoice[0].valueMultiChoice == act.actionValues[j].value) {
                                                act.singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                }
                                this.cnt++
                            }
                            this.actions[i].actionValues = []
                            this.actions[i].actionValues = List
                        }
                    }
                }
            })
        },
        updateRecallActions() {
            recallServices.getRecallSummaryById(this.communicationId,this.providerCommunicationId).then((resp) => {
                if (resp.data.d.completed_at) {
                    this._showToast( this.communicationType == this.recall ?
                        'Cannot modify actions as recall is marked completed' : 'Cannot modify actions as advisory is marked completed',
                    { variant: 'danger' }
                    )
                } else {

                    var act = this.actions
                    for (let i = 0; i < this.actions.length; i++) {
                        if (act[i].action_type == 'MultipleChoice') {
                            if (act[i].multiSelectedValues == null && (act[i].singleSelectedValues == null || act[i].singleSelectedValues == 0)) {
                                this.actions[i].singleSelectedValues = 0
                            }
                            else if (act[i].multiSelectedValues.length == 0 && (act[i].singleSelectedValues == null || act[i].singleSelectedValues == 0)) {
                                this.actions[i].singleSelectedValues = 0
                            }
                        }
                    }
                    recallServices
                        .updateRecallActions(this.communicationId, this.communicationType, this.actions,this.providerCommunicationId)
                        .then((resp) => {
                            if (!resp.error) {
                                this._showToast('Successfully Updated!', {
                                    variant: 'success',
                                })
                            }
                        })
                }
            })
        },
        expandMoreLess(action) {
            if(action.isCustomActionType)
            {
                action.isExpanded=action.isCustomActionType ?!action.isExpanded:action.isExpanded
            }
        },
    },
}
