import { EMAIL_STATUS,RECALL_CLASSIFICATIONS, RECALL_STATUS,OUTPUT_DATE_FORMAT, COMMUNICATION_TYPE, PRODUCT_DISRUPTION_TYPE, PROVIDER_EVENT_STATUS, ANTICIPATED_DURATION } from '../../../../../misc/commons'
import DocumentViewModel from '../../../../../components/part/shared/DocumentViewModel'
import ActionType from '../../../../../components/part/provider/ActionType'
import recallServices from '../../../../../services/provider/recall'
import ErrorModal from '../../../../share/ErrorModal'
export default {
    props: {
        communicationId: {type: String, default: null},
        providerCommunicationId: {type: String, default: ''},
        respDetails: {type: Array, default: null},
        communicationType: {type: String, default: ''},
    },
    components: {
        DocumentViewModel,
        ActionType,
        ErrorModal
    },
    data() {
        return {
            supplierCcEmailsCombine: null,
            errorModalKey: 0,
            recall_classifications: RECALL_CLASSIFICATIONS,
            provider_event_status: PROVIDER_EVENT_STATUS,
            recall_status: RECALL_STATUS,
            anticipated_duration: ANTICIPATED_DURATION,
            emailStatus: EMAIL_STATUS,
            item: null,
            sites: null,
            showDelete: true,
            disruption_type: PRODUCT_DISRUPTION_TYPE,
            labels: {},
            recall: COMMUNICATION_TYPE.RECALL,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
        }
    },
    created() {
        this.getRecallById()
        if ((this.$route.query.nonAck && this.$route.query.nonAck !='') ||this.$route.query.tabName ==='tabDetails' || (this.$route.query.pageName && this.$route.query.pageName !='')) {
            this.$store.state.lastedRouteNotAuth=''
        }
    },
    methods: {
        getRecallById() {
            recallServices.getRecallById(this.communicationId,this.providerCommunicationId).then(resp => {
                if (!resp.error){
                    this.item = resp.data.d
                    this.supplierCcEmailsCombine = this.item.supplierRepCCEmails.concat(this.item.additionalCcEmails)
                    this.item.updates.forEach(s => {
                        s.disruptionUpdate = s.disruptionUpdate.replaceAll('ql-align-right', 'text-right').replaceAll('ql-align-center', 'text-center')
                            .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                            .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                            .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8')
                    })
                    if(resp.data.d.completed_at || resp.data.d.firstCompletedDatetime !== null)
                    {
                        this.showDelete=false
                    }
                    else{
                        this.showDelete=true
                    }
                    if(this.item.responsestatus === 'BOUNCE')
                    {
                        this.item.responsestatus = 'ERROR'
                    }
                    this.labels={
                        type: this.communicationType == this.recall ? 'Recall Type' : 'Type',
                        categories: this.communicationType == this.recall ? 'Recall Categories': 'Category',
                        riskToHealth: this.communicationType == this.recall ? 'Risk to Health': 'Alternatives & Conservation Measures',
                        attachments: this.communicationType == this.recall ? 'Supporting Materials': 'Attachments',
                        products: this.communicationType == this.recall ? 'Recall Products': 'Affected Products',
                        status: this.communicationType == this.recall ? (this.recall && this.item.isProviderCreated ? 'Response Status' : 'Supplier Event Status') : 'Status'
                    }
                    this.$emit('onReLoadCommunicationData')
                    this.$emit('onReLoadHistoryData')
                    this.$emit('onLoad',this.item.type)
                }
            })
        },
        errorModel(val) {
            if(val.responsestatus == this.emailStatus.ERROR && val.isProviderCreated)
            {
                let data = []
                data.bounce_sub_type = val.bounceSubType
                data.bounce_type = val.bounceType
                data.description = val.errorDescription
                data.extra_details = val.extra_details
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
        },
        reLoad(){
            this.$emit('onReLoad',this.item.type)
        },
        async download(dataurl, filename, contentType) {
            this.openExternalLink(dataurl)
        },
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        }
    }
}