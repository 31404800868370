import recallService from '../../../services/provider/recall'
import Vue from 'vue'
import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)
export default {
    props: {
        details: {type: Object, default: null},
        communicationType: {type: String, default: ''},
    },
    data() {
        return {
            recallDetails: null,
            bodyContent: null,
            comments: '',
            admin: [],
            item: [],
            tinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                nonbreaking_force_tab: true,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function(ed) {
                    ed.on('keydown', function(evt) {
                        if (evt.keyCode == 9){
                            ed.execCommand('mceInsertContent', false, '&emsp;&emsp;&emsp;')
                            evt.preventDefault()
                        }
                    })
                }
            },
        }
    },
    computed: {
        form() {
            if(this.communicationType == 'RECALL'){
                return {
                    title: 'Would you like to send recall response to supplier?',
                }
            }
            if(this.communicationType == 'PRODUCT DISRUPTION')
            {
                return {
                    title: 'Would you like to send supply advisory response to supplier?',
                }
            }
        },
        GetUserRole() {
            return this.$store.state.roleName
        },
    },
    created() {
        this.getUserData()
        this.getEmail('PROVIDER_RECALL_RESPONSE')
    },
    methods: {
        getEmail(type) {
            recallService.getEmail(type).then(resp => {

                if (!resp.error) {
                    this.items = resp.data.d
                    this.bodyContent = /<body.*?>([\s\S]*)<\/body>/.exec(this.items.content)[1]
                    let htmlSplit = this.bodyContent.split('<p')
                    var htmlStr = ''
                    var footerCnt = 0
                    for (let index = 0; index < htmlSplit.length; index++) {
                        if (htmlSplit[index].includes('Sincerely')) {
                            footerCnt++
                        }
                        if (footerCnt == 0) {
                            htmlStr =  htmlStr + '<p class="mb-0"  style="line-height: 1.4;margin-bottom: 10px !important;color: black;"' + htmlSplit[index]
                        }
                        else
                        {
                            if (htmlSplit[index].includes('Sincerely')) {
                                htmlStr =  htmlStr + '<p class="mb-0" style="color: black;font-weight: 600;padding-bottom: 3px;"' + htmlSplit[index]
                            }
                            else
                            {
                                htmlStr =  htmlStr + '<p class="mb-0" style="color: black;padding-bottom: 3px;"' + htmlSplit[index]
                            }
                        }
                    }
                    this.bodyContent = htmlStr
                    this.bodyContent = this.bodyContent.replace('${SupplierName}','<span style="font-weight: 600;">' + (this.item.suppliername == undefined ? this.item.supplier_name : this.item.suppliername) + '</span>')
                    this.bodyContent= this.bodyContent.replace('${EventName}','<span style="font-weight: 600;">' + (this.item.name == undefined ? this.item.communicationName : this.item.name) + '</span>')
                    this.bodyContent = this.bodyContent.replace('${ProviderRole}','<span style="font-weight: 600;">' + this.GetUserRole + '</span>')
                    this.bodyContent = this.bodyContent.replace('${ProviderUserName}','<span style="font-weight: 600;">' + this.admin.name + '</span>')
                    this.bodyContent = this.bodyContent.replace('${ProviderName}','<span style="font-weight: 600;">' + this.admin.companyname + '</span>')
                    this.bodyContent = this.bodyContent.replace('${ProviderUserSite}','<span style="font-weight: 600;">' + this.admin.userSite + '</span>')
                    this.bodyContent = this.bodyContent.replace('<p>${Comments}</p>', '')
                    this.bodyContent = this.bodyContent.replace('<p class="mb-0"  style="line-height: 1.4;margin-bottom: 10px !important;color: black;">${Comments}</p>', '')
                    this.bodyContent = this.bodyContent.replace('${Comments}', ' ')
                    if (this.admin.title == undefined || this.admin.title == null) {
                        this.bodyContent = this.bodyContent.replace('<p class="mb-0" style="color: black;padding-bottom: 3px;" style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserTitle}</p>', '')
                        this.bodyContent = this.bodyContent.replace('${ProviderUserTitle}', ' ')
                    }
                    else
                    {
                        this.bodyContent = this.bodyContent.replace('${ProviderUserTitle}','<span style="font-weight: 600;">' +  this.admin.title)
                    }

                    if (this.admin.email == undefined || this.admin.email == null) {
                        this.bodyContent = this.bodyContent.replace('<p class="mb-0" style="color: black;padding-bottom: 3px;" style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserEmailAddress}</p>', '')
                        this.bodyContent = this.bodyContent.replace('${ProviderUserEmailAddress}', ' ')
                    }
                    else
                    {
                        this.bodyContent = this.bodyContent.replace('${ProviderUserEmailAddress}', '<span style="font-weight: 600;">' + this.admin.email)
                    }

                    if (this.admin.phoneno == undefined || this.admin.phoneno == null) {
                        this.bodyContent = this.bodyContent.replace('<p class="mb-0" style="color: black;padding-bottom: 3px;" style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserPhoneNumber}</p>', '')
                        this.bodyContent = this.bodyContent.replace('${ProviderUserPhoneNumber}', ' ')
                    }
                    else
                    {
                        var x = this.admin.phoneno.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                        var newvalue = !x[2] ? x[1] :  x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
                        this.bodyContent = this.bodyContent.replace('${ProviderUserPhoneNumber}','<span style="font-weight: 600;">' +  newvalue)
                    }

                    if (this.admin.userSite == undefined || this.admin.userSite == null) {
                        this.bodyContent = this.bodyContent.replace('<p class="mb-0" style="color: black;padding-bottom: 3px;" style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserSite}</p>', '')
                        this.bodyContent = this.bodyContent.replace('${ProviderUserSite}', ' ')
                    }
                    else
                    {
                        this.bodyContent = this.bodyContent.replace('${ProviderUserSite}','<span style="font-weight: 600;">' +  this.admin.userSite)
                    }
                }
            })
        },
        getUserData() {
            if (this.$store.state.user != '') {
                var userData = this.$store.state.user
                this.admin.name = userData.fname + ' ' + userData.lname
                this.admin.email = userData.email
                this.admin.title = userData.title
                this.admin.companyname = userData.org_name
                this.admin.phoneno = userData.phone ?? ''
                this.admin.userSite = (userData.userSite == null || userData.userSite == '') ? userData.location : userData.userSite
            }
            else {
                this.admin.name = ''
                this.admin.email = ''
                this.admin.title = ''
                this.admin.companyname = ''
                this.admin.phoneno = ''
                this.admin.userSite = ''
            }
        },
        forceRerenderInsert(val) {
            this.item = val
            this.$refs.modal.show()
        },
        sendEmail() {
            recallService.providerResponseMail({
                communicationId: this.details.id,
                providerCommunicationId: this.details.providerrecallid,
                comment: this.comments
            }).then(resp => {
                if (!resp.error){
                    this.cancel()
                    this.$emit('onSubmit','yes')
                    this._showToast(this.communicationType == 'RECALL'? 'Recall response has been sent.': 'Supply Advisory response' + ' has been sent successfully!', {variant: 'success'})
                }
            })
        },
        cancel() {
            this.$refs.modal.hide()
            this.$emit('onSubmit','no')
        },
    }

}